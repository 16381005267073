import React from 'react'
import { Redirect } from 'react-router-dom'
import { Route } from 'react-router-dom'
import * as FeatherIcon from 'react-feather'

import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils'

// auth
const Login = React.lazy(() => import('../pages/auth/Login'))
const Logout = React.lazy(() => import('../pages/auth/Logout'))
// dashboard
const Dashboard = React.lazy(() => import('../pages/dashboard'))
// Yonetici
const YoneticiEkle = React.lazy(() => import('../pages/apps/Yonetici/Ekle'))
const YoneticiGoruntule = React.lazy(() =>
  import('../pages/apps/Yonetici/Goruntule')
)
// Kullanıcı
const KayitliKullanicilar = React.lazy(() =>
  import('../pages/apps/Kullanici/KayitliKullanicilar')
)

// Ilan
const Ilan = React.lazy(() => import('../pages/apps/Ilan/Ilan'))

const KategoriEkle = React.lazy(() => import('../pages/apps/Kategori/Ekle'))
const KategoriDuzenle = React.lazy(() =>
  import('../pages/apps/Kategori/Duzenle')
)

const ReklamEkle = React.lazy(() => import('../pages/apps/Reklam/Ekle'))
const ReklamGoruntule = React.lazy(() =>
  import('../pages/apps/Reklam/Goruntule')
)

// Şikayetler
const Sikayetler = React.lazy(() =>
  import('../pages/apps/Sikayetler/Sikayetler')
)
// Bildirim
const Bildirim = React.lazy(() => import('../pages/apps/Bildirim/Bildirim'))


// Conversations
const ConversationView = React.lazy(() => import("../pages/apps/Conversations/View"))

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      if (!isUserAuthenticated()) {
        // not logged in so redirect to login page with the return url
        return (
          <Redirect
            to={{ pathname: '/account/login', state: { from: props.location } }}
          />
        )
      }

      const loggedInUser = getLoggedInUser()
      // check if route is restricted by role
      if (roles && roles.indexOf(loggedInUser.role) === -1) {
        // role not authorised so redirect to home page
        return <Redirect to={{ pathname: '/' }} />
      }

      // authorised so return component
      return <Component {...props} />
    }}
  />
)

// root routes
const rootRoute = {
  path: '/',
  exact: true,
  component: () => <Redirect to={'/anasayfa'} />,
  route: PrivateRoute,
}

// dashboards
const dashboardRoutes = {
  path: '/anasayfa',
  name: 'Anasayfa',
  icon: FeatherIcon.Home,
  component: Dashboard,
  roles: ['Admin'],
  route: PrivateRoute,
}

const yoneticiRoutes = {
  path: '/yonetici',
  name: 'Yönetici İşlemleri',
  icon: FeatherIcon.Award,
  children: [
    {
      path: '/yonetici/ekle',
      name: 'Yönetici Ekle',
      component: YoneticiEkle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/yonetici/goruntule',
      name: 'Yönetici Görüntüle',
      component: YoneticiGoruntule,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}

const kullaniciRoutes = {
  path: '/kullanici',
  component: KayitliKullanicilar,
  name: 'Kullanıcı İşlemleri',
  icon: FeatherIcon.User,
  route: PrivateRoute,
  roles: ['Admin'],
}
const ilanRoutes = {
  path: '/ilan',
  component: Ilan,
  name: 'İlan İşlemleri',
  icon: FeatherIcon.Columns,
  route: PrivateRoute,
  roles: ['Admin'],
}

const promoRouter = {
  path: '/reklam',
  name: 'Reklam İşlemleri',
  icon: FeatherIcon.Package,
  children: [
    {
      path: '/reklam/ekle',
      name: 'Reklam Ekle',
      component: ReklamEkle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/reklam/goruntule',
      name: 'Reklam Görüntüle',
      component: ReklamGoruntule,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}

const categoryRouter = {
  path: '/kategori',
  name: 'Kategori İşlemleri',
  icon: FeatherIcon.Grid,
  children: [
    {
      path: '/kategori/ekle',
      name: 'Kategori Ekle',
      component: KategoriEkle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
    {
      path: '/kategori/goruntule',
      name: 'Kategori Görüntüle',
      component: KategoriDuzenle,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}

const conversationsRouter = {
  path: '/conversations',
  name: 'Sohbetler',
  icon: FeatherIcon.Mail,
  children: [
    {
      path: '/conversations/view',
      name: 'Sohbetleri Görüntüle',
      component: ConversationView,
      route: PrivateRoute,
      roles: ['Admin'],
    },
  ],
}

const complaintRoutes = {
  path: '/sikayetler',
  name: 'Şikayetler',
  icon: FeatherIcon.Flag,
  component: Sikayetler,
  roles: ['Admin'],
  route: PrivateRoute,
}

const notificationsRoutes = {
  path: '/bildirim',
  component: Bildirim,
  name: 'Bildirim',
  icon: FeatherIcon.Bell,
  route: PrivateRoute,
  roles: ['Admin'],
}

// auth
const authRoutes = {
  path: '/account',
  name: 'Auth',
  children: [
    {
      path: '/account/login',
      name: 'Login',
      component: Login,
      route: Route,
    },
    {
      path: '/account/logout',
      name: 'Logout',
      component: Logout,
      route: Route,
    },
  ],
}

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
  let flatRoutes = []

  routes = routes || []
  routes.forEach((item) => {
    flatRoutes.push(item)

    if (typeof item.children !== 'undefined') {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)]
    }
  })
  return flatRoutes
}

// All routes
const allRoutes = [
  rootRoute,
  dashboardRoutes,
  yoneticiRoutes,
  kullaniciRoutes,
  conversationsRouter,
  ilanRoutes,
  categoryRouter,
  promoRouter,
  //complaintRoutes,
  notificationsRoutes,
  authRoutes,
  
]

const authProtectedRoutes = [
  dashboardRoutes,
  yoneticiRoutes,
  kullaniciRoutes,
  conversationsRouter,
  ilanRoutes,
  categoryRouter,
  promoRouter,

  //complaintRoutes,
  notificationsRoutes,
 
]
const allFlattenRoutes = flattenRoutes(allRoutes)
export { allRoutes, authProtectedRoutes, allFlattenRoutes }
